import React, { useEffect, useState } from 'react';
import anime from "animejs";
import "./App.css";

function App() {
  
  useEffect(() => {
    const createSnowflakes = () => {
      const snowflakes = [];
      const totalSnowflakes = 100;
      const container = document.querySelector(".snowflakes");

      for (let i = 0; i < totalSnowflakes; i++) {
        const snowflake = document.createElement("div");
        snowflake.className = "snowflake";
        snowflake.style.left = `${Math.random() * 100}%`;
        snowflake.style.animationDelay = `${Math.random() * 5}s`;
        snowflake.style.animationDuration = `${2 + Math.random() * 3}s`;
        container.appendChild(snowflake);
        snowflakes.push(snowflake);
      }

      anime({
        targets: snowflakes,
        translateY: [
          { value: "100vh", duration: 5000, easing: "linear" },
          { value: "-100vh", duration: 0, easing: "linear" },
        ],
        loop: true,
        delay: anime.stagger(50, { start: 0 }),
      });
    };

    createSnowflakes();

    anime({
      targets: ".profile-pic",
      scale: [0, 1],
      duration: 1000,
      easing: "easeInOutExpo",
      delay: 500,
    });

    anime({
      targets: ".left-side h1",
      translateY: [50, 0],
      opacity: [0, 1],
      duration: 1000,
      easing: "easeInOutExpo",
      delay: 1000,
    });
  }, []);

  return (
     <>
 
    
    <div className="App">
      <div className="snowflakes"></div>
      <div className="container">
        <div className="left-side">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkWxkqs_HxznBnzX3CtyqdyyHZHclkiuiRFQ&s"
            alt="Your Photo"
            className="profile-pic"
          />
          <h1>Nalin Nishant</h1>
        </div>
        <div className="right-side">
          <div className="content">
            <section>
              <h2>About Me</h2>
              <p>
                Software Engineer with experience in microservices architecture,
                improving API response times, and integrating new features to
                enhance user engagement. Skilled in Java, Spring Boot, React,
                and more.
              </p>
            </section>
            <section>
              <h2>Experience</h2>
              <h3>Virtusa (May 2022 – Present)</h3>
              <p>
                <ul>
                  <li>
                    Implemented microservices architecture using Spring Boot,
                    improving API response time by 25% and reducing server load
                    by 30%.
                  </li>
                  <li>
                    Developed a cron job feature utilizing Spring Boot to
                    monitor completion status and alert the appropriate
                    individual, resulting in a 60% enhancement in response time.
                  </li>
                  <li>
                    Streamlined user onboarding workflows by implementing new
                    methods in the existing SDK and integrating enhanced APIs
                    across multiple applications, resulting in a 20% reduction
                    in user drop-offs and an increase in successful user
                    activations by 10%.
                  </li>
                  <li>
                    Guided a cross-functional team in integrating a new feature
                    utilizing React and Redux, leading to a 20% surge in user
                    engagement during the initial month.
                  </li>
                  <li>
                    Spearheaded the execution of Proof of Concept (POC) tasks to
                    validate the feasibility of sentiment analysis LLM Model and
                    Kafka; devised streamlined workflows utilizing AWS Secret
                    Manager. Achieved a 40% reduction in total dissatisfaction.
                  </li>
                  <li>
                    Mentored and guided new interns, ensuring their seamless
                    integration into the team and providing them with valuable
                    insights and training on project adaptors, collaborated with
                    cross-functional consuming teams and provided training to
                    other team members on the functionality of the adaptors.
                  </li>
                </ul>
              </p>
              <h3>Virtusa (Dec 2021 – Feb 2022)</h3>
              <p>
                <ul>
                  <li>
                    Resolved minor bugs within the Java backend services by
                    conducting API testing with Postman.
                  </li>
                  <li>
                    Received hands-on training in Spring Boot and React.
                    Achieved a score of 85% in an internal EIQ test.
                  </li>
                </ul>
              </p>
            </section>
            <section>
              <h2>Projects</h2>
              <h3>Blogify</h3>
              <p>
                Developed a Blogify Application that allows users to publish &
                manage their posts after authentication based on their
                role-based access. Implemented a REST API Controller class with
                proper mapping and support for common HTTP methods, and
                integrated Spring Security JPA for role-based authentication.
              </p>
            </section>
            <section>
              <h2>Education</h2>
              <p>
                <strong>Chandigarh Group Of Colleges</strong>
                <br />
                B. Tech in Computer Science & Engineering (Aug 2018 - July 2022)
              </p>
            </section>
            <section>
              <h2>Achievements</h2>
              <ul>
                <li>First Prize in Project Display out of 500 participants.</li>
                <li>
                  Bronze Medal in HackerRank Hackfest out of 8051 participants.
                </li>
                <li>
                  Qualified JEE Mains.<br></br>
                  <br></br>
                </li>
              </ul>
            </section>
          </div>
        </div>
      </div>
    </div>
 </>
  );
}

export default App;
